import { render, staticRenderFns } from "./newsCenter.vue?vue&type=template&id=a84fc3ce&scoped=true&"
import script from "./newsCenter.vue?vue&type=script&lang=js&"
export * from "./newsCenter.vue?vue&type=script&lang=js&"
import style0 from "./newsCenter.vue?vue&type=style&index=0&id=a84fc3ce&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a84fc3ce",
  null
  
)

export default component.exports